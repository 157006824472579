import React from "react";
import { Link } from "gatsby";
const Footer = ({ siteTitle }) => (
  <footer>
    <div>
      <div className="main-footer">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6 col-lg-6">
              <ul className="footer-links">
                <li>
                  <Link to="/" activeClassName="active">
                    Home
                  </Link>
                </li>

                <li>
                  <Link to="/about" activeClassName="active">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/projects" activeClassName="active">
                    Projects
                  </Link>
                </li>
                <li>
                  <Link to="/services" activeClassName="active">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/contact" activeClassName="active">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-6">
              <ul className="footer-contact-info">
                <li>
                  <a href="tel:087 984 9911">087 984 9911</a>
                </li>
                <li>
                  <a href="mailto:info@ggcleaning.ie">info@ggcleaning.ie</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row d-flex align-items-center ">
            <div className="col-md-6 col-lg-6 _left">
              <p>GG Cleaning. All Rights Reserved. </p>
            </div>
            <div className="col-md-6 col-lg-6 _right">
              <p>
                <a
                  href="https://yellow-llama.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Website Design and Development TYL
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
