import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import logo from "../../src/assets/img/logo.png";

const Header = ({ siteTitle }) => (
  <div>
    <header className="nav-section" id="nav-section">
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-2 col-md-3 col-3">
            <Link to="/" className="logo">
              {" "}
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="col-lg-5 col-md-7 col-7">
            <div className="header-contact-info ">
              <ul>
                <li>
                  <a href="tel:087 984 9911">087 984 9911</a>
                </li>
                <li>
                  <a href="tel:094 902 6734">094 902 6734</a>
                </li>
                <li>
                  <a href="mailto:info@ggcleaning.ie">info@ggcleaning.ie</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-lg-5 col-2 main-menu">
            <ul>
              <li>
                <Link to="/about" activeClassName="active">
                  About
                </Link>
              </li>
              <li>
                <Link to="/projects" activeClassName="active">
                  Projects
                </Link>
              </li>
              <li>
                <Link to="/services" activeClassName="active">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/contact" activeClassName="active">
                  Contact
                </Link>
              </li>
            </ul>
            <div className="mobile-menu-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                <path
                  style={{
                    lineHeight: "normal",
                    textIndent: 0,
                    textAlign: "start",
                    textDecorationLine: "none",
                    textDecorationStyle: "solid",
                    textDecorationColor: "#000",
                    textTransform: "none",
                    blockProgression: "tb",
                    isolation: "auto",
                    mixBlendMode: "normal",
                  }}
                  d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </header>
    <nav className="mobile-menu-wrap">
      <div className="close-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24px"
          height="24px"
        >
          <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
        </svg>
      </div>
      <div id="mobile-menu1">
        <ul>
          <li>
            <Link to="/" activeClassName="active">
              Home
            </Link>
          </li>

          <li>
            <Link to="/about" activeClassName="active">
              About
            </Link>
          </li>
          <li>
            <Link to="/projects" activeClassName="active">
              Projects
            </Link>
          </li>
          <li>
            <Link to="/services" activeClassName="active">
              Services
            </Link>
          </li>
          <li>
            <Link to="/contact" activeClassName="active">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
