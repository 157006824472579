import React from 'react';
export class CalltoAction extends React.Component {

	render() {
		return (
			<div>
				<section className="request-section section-gap" style={{ backgroundImage: `url(${this.props.bgimage})` }}>
					<div className="container">
						<div className="about-text call-to-action">
							<h2>{this.props.heading}</h2>
							<div className="text-center">
								<a href={this.props.action} className="_btn" style={{ 'backgroundColor': this.props.btnbgcolor, 'color': this.props.btncolor }}>{this.props.btnval}</a>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}
export default CalltoAction; 
